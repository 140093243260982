<template>
    <el-dialog :close-on-click-modal="false" title="添加人员" :visible.sync="show" width="500px" class="selfInputBox">
        <el-form v-if="formShow" ref="form" :model="value" label-width="80px">
            <el-form-item label="名字" prop="title">
                <el-input v-model="value.title" />
            </el-form-item>
            <el-form-item label="序号" prop="name1">
                <el-input v-model="value.name1" />
            </el-form-item>
            <el-form-item label="状态" prop="name2">

                <el-switch v-model="state" active-color="#5BD995" active-text="正常" inactive-color="#D9D9D9"
                    inactive-text="禁用" class="switchSelfBox">
                </el-switch>


            </el-form-item>
            <el-form-item label="头像" prop="name3">


                <el-upload drag class="SelfUploadBox" action :auto-upload="false" list-type="picture-card"
                    :on-preview="handlePreview" :on-remove="handlePreview" :before-remove="beforeRemove"
                    :on-change="uploadSectionFile" :limit="1" ref="businessLicense">
                    <i class="el-icon-upload"></i>
                    <div class="el-upload__text">将文件拖拽到此处或点击上传</div>
                </el-upload>


            </el-form-item>
        </el-form>


        <span slot="footer">
            <div class="buttons">
                <el-button type="primary" @click="refresh">保存</el-button>
                <!-- <el-button @click="$refs.form.resetFields()">重置</el-button> -->
            </div>
        </span>
    </el-dialog>
</template>

<script>
    export default {
        // model: {
        //     prop: 'value',
        //     event: 'change'
        // },
        // props: {
        //     value: {
        //         type: Object,
        //         required: true
        //     }
        // },
        data() {
            return {
                show: false,
                formShow: false,
                state: true,


                // 回显图片的路径
                photoList: [{
                    url: ""
                }],
                // 图片的路径
                dialogImageUrl: "",





            }
        },
        methods: {
            open() {
                this.formShow = true
                this.show = true
            },
            refresh() {
                this.show = false
                this.$emit('refresh')
                setTimeout(() => {
                    this.formShow = false
                }, 1000)
            },

            uploadSectionFile(file) {

                // 图片的路径
                this.dialogImageUrl = file.url;
                console.log("111111" + file.url);
            },



            beforeRemove(file) {

                // 图片的路径
                this.dialogImageUrl = file.url;
                console.log("22222" + file.url);
            },

            handlePreview(file) {

                // 图片的路径
                this.dialogImageUrl = file.url;
                console.log("33333" + file.url);
            },




        }
    }
</script>